const loadGoogleMapsApi = require('load-google-maps-api');
	
document.addEventListener('DOMContentLoaded', function() {
    
	let el = document.getElementById('map'),
		markers = [],
		map, 
		initialized = false,
		locations = [],
		markerHeight = 29,
		markerWidth = 38,
		mydata = [],
		mapItems = [],
		bounds,
		key = el.dataset.key,
		mapItemsUrl = el.dataset.json;    

    const addMarkers = function() {

        for (let i = 0; i < mydata.length; i++) {

            let coords = mydata[i].coordinates,
                position = new google.maps.LatLng(coords[0], coords[1]),
                markerUrl = mydata[i].marker;

            let image = {
				url: markerUrl,
				size: new google.maps.Size(markerWidth, markerHeight),
				scaledSize: new google.maps.Size(markerWidth, markerHeight),
				origin: new google.maps.Point(0, 0),
				anchor: new google.maps.Point(markerWidth/2, markerHeight),
			}

            let marker = new google.maps.Marker({
                position: position,
                map: map,
                icon: image,
				animation: google.maps.Animation.DROP,
            });
            bounds.extend(position);
        }
	};

    const loadDataFromFile = function(arg) {
                
        let request = new XMLHttpRequest();
        
        request.open('GET', arg, true);
        request.send(null);
        
        console.log(arg);

        request.onreadystatechange = function() {
            if (request.readyState == 4) {
                if (request.status == 200) {
                                        
                    console.log('map items loaded:');
                    
                    mydata = JSON.parse(request.responseText);                           
                    
                    init();

                } else {
                    console.log(request.status, request.statusText);
                }
            }
        };        
    };
    
    const centerMapWithMarkers = function() {
        
        // zmniejszamy przybliżenie
        let minDistance = 0.00198,
            sumA = bounds.getNorthEast().lng() - bounds.getSouthWest().lng(),
            sumB = bounds.getNorthEast().lat() - bounds.getSouthWest().lat();
        if ((sumA < minDistance && sumA > -minDistance) && (sumB < minDistance && sumB > -minDistance)) {
            var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + minDistance, bounds.getNorthEast().lng() + minDistance),
                extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - minDistance, bounds.getNorthEast().lng() - minDistance);
            bounds.extend(extendPoint1);
            bounds.extend(extendPoint2);
        }
        
        // center map to markers 
		map.fitBounds(bounds);
    };

	const getLocations = function(map) {

        bounds = new google.maps.LatLngBounds();
        addMarkers();
        centerMapWithMarkers();       

	};
	
	const openUrl = function() {	
		window.location.href = this.url;
	}

	const init = function() {
    	console.log('map init');

    	loadGoogleMapsApi({
            key: key
        }).then(function (googleMaps) {
            map = new google.maps.Map(el, {
                center: {
                    lat: 40.7484405,
                    lng: -73.9944191
                },
                disableDefaultUI: true,
                zoom: 12
            })

            getLocations(map);
            
        }).catch(function (error) {
            console.error(error)
        });
        
        
	};


	el ? loadDataFromFile(mapItemsUrl) : false;
    	

}, false);
