import css from './sass/style.scss';

require('./js/carousels');
require('./js/polyfills');
require('./js/fonts');
require('./js/helpers');
require('./js/init');
require('./js/ismobile');
require('./js/map');
require('./js/nav');
require('./js/validate');
require('./js/video');
require('./js/smoothscroll');

require('./img/icons/location--green.svg');
require('./img/favicon.png');
