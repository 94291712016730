document.addEventListener('DOMContentLoaded',function() {

    window.videoInit = function() {
        
        const cover = document.getElementsByClassName('js-videocover')[0];
        const videocontent = document.getElementsByClassName('js-videocontent')[0];
        let start = false;

        const tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";

        const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        
        var player;

        // 3. This function creates an <iframe> (and YouTube player)
        //    after the API code downloads.
        window.onYouTubeIframeAPIReady = function() {
            player = new YT.Player('player', {
                height: '360',
                width: '640',
                videoId: 'CDYN3qWdw9M',
                events: {
                    'onReady': onPlayerReady,
                    'onStateChange': onPlayerStateChange
                },
                playerVars: {rel: 0, controls: 0},
            });
            
            videocontent.classList.remove('is-hidden');
        };
        
        // 4. The API will call this function when the video player is ready.
        function onPlayerReady(event) {
            
            player.mute();
            event.target.playVideo();

            cover.addEventListener('click', function() {
                    
                if (cover.classList.contains('fade-out')) {
                    cover.classList.remove('fade-out');
                    
                    event.target.pauseVideo();
                } else {
                    cover.classList.add('fade-out');
                    event.target.playVideo();
                    player.unMute();
                }
            });
        }

        const onPlayerStateChange = function(event) {
            if (event.data == YT.PlayerState.PLAYING && !start) {
                pauseVideo();
                start = true;
            }
            
            if (event.data == 0) {
                cover.classList.remove('fade-out');
            }
        }
     
        const pauseVideo = function() {
            player.pauseVideo();
        }
        
        const stopVideo = function() {
            player.stopVideo();
        }
    };

}, false);
