(() => {

    const WebFont = require('webfontloader');

    WebFont.load({

        google: {
            families: ['Inter:400,500:latin-ext']
        }
    });
})();
