import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper.scss';

document.addEventListener('DOMContentLoaded', () => {
    const version = document.querySelector('.js-version');

    const versionCarousel = () => {
        const swiper = new Swiper(version, {
            modules: [Navigation, Pagination],
            autoHeight: true,
            centeredSlides: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            speed: 400,
            pagination: {
                el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: false
            },
        });
    }
    window.carousels = () => {
        version ? versionCarousel() : false;
    }
}, false)
